import React, { PropsWithChildren } from "react";
import { SWRConfig } from "swr";
import useSWRMutation, {
    SWRMutationConfiguration,
    SWRMutationResponse
} from "swr/mutation";
// @ts-ignore
import camelize from "camelize";

import { useAuth } from "hooks/use-auth";
import { getFullData, sendData, throwIfNotOk } from "utils/http-requests";

type MethodT = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type FetcherKeyT = string | { url: string; method: MethodT };

const fetcher = async <Args = void,>(
    key: FetcherKeyT,
    options: { arg?: { data?: Args } } | void,
    token: string,
    camelizeData = false
) => {
    const isString = typeof key === "string";
    const url = isString ? key : key.url;
    const method = isString ? "GET" : key.method;
    const fetchResult =
        method === "GET"
            ? getFullData(url, token).then(throwIfNotOk)
            : sendData({
                  url,
                  ...(options?.arg?.data ? { data: options.arg.data } : {}),
                  token,
                  method
              }).then(throwIfNotOk);
    const { data: result } = await fetchResult;
    return camelizeData ? camelize(result) : result;
};

export const useSWRFetcher = (props?: { camelizeData?: boolean }) => {
    const { token } = useAuth();
    return {
        fetcher: async <Args = void,>(
            key: FetcherKeyT,
            options: { arg?: { data?: Args } } | void
        ) => fetcher(key, options, token, props?.camelizeData)
    };
};

export const useSWRGlobalMutation = <
    Data = any,
    Error = any,
    Args = any | void
>(
    key?: FetcherKeyT,
    config?: SWRMutationConfiguration<Data, Error, Args>
): SWRMutationResponse<Data, Error, Args> => {
    const { fetcher } = useSWRFetcher({ camelizeData: true });
    return useSWRMutation(
        key,
        (key: FetcherKeyT, options?: any) => fetcher(key, options),
        config
    );
};

type SWRContextProps = PropsWithChildren<{}> & { camelizeData?: boolean };

export const SWRContext = ({
    children,
    camelizeData = true
}: SWRContextProps) => {
    const { fetcher } = useSWRFetcher({ camelizeData });
    return (
        <SWRConfig
            value={{
                fetcher
            }}
        >
            {children}
        </SWRConfig>
    );
};
